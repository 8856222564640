:root {
    --primary: #499d3a;
    --secondary: #308421;
    --light: #f7f7f7;
    --white: #ffffff;
    --black: #2e2d2e;
    --small: 39.9375em;
    --medium: 40em;
    --large: 64em;
    --headerSize: 65px;
}

@font-face {
    font-family: 'Careem-Medium';
    src: local('Careem-Bold'), url(./fonts/Careem-Bold.otf) format("opentype"), url(./fonts/Careem-Bold.eot) format("embedded-opentype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Careem-Bold';
    src: local('Careem-Bold'), url(./fonts/Careem-Bold.otf) format("opentype"), url(./fonts/Careem-Bold.eot) format("embedded-opentype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Careem-Regular';
    src: local('Careem-Regular'), url(./fonts/Careem-Regular.otf) format("opentype"), url(./fonts/Careem-Regular.eot) format("embedded-opentype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Careem-Light';
    src: local('Careem-ReguLightlar'), url(./fonts/Careem-Light.otf) format("opentype"), url(./fonts/Careem-Light.eot) format("embedded-opentype");
    font-weight: normal;
    font-style: normal
}

.careem-bold {
    font-family: "Careem-Bold";
}

.careem-regular {
    font-family: "Careem-Regular" !important;
}

.careem-light {
    font-family: "Careem-Light";
}

.logo {
    width: 178px;
    height: 35px;
    color: white !important;
}

.text-white {
    color: var(--white);
}

.bg-careem {
    background-color: var(--primary) !important;
}

.navbar-nav .nav-link {
    border: 1px solid var(--white);
    border-radius: 5px;
    margin-right: 5px;
}

.navbar-nav .nav-item .nav-link {
    color: var(--white) !important;
    font-family: "Careem-Light";
}

.navbar-nav .active .nav-link {
    font-family: "Careem-Regular" !important;
    background-color: var(--white);
    color: var(--primary) !important;
}

.title {
    font-size: 1.2em;
}

.careem-button-primary {
    background-color: var(--primary);
    border-color: var(--secondary);
}

.careem-button-primary:hover {
    background-color: var(--secondary) !important;
    border-color: var(--primary) !important;
}

body {
    font-family: "Careem-Light";
}

code {
    color: var(--secondary);
}

.dropzone {
    border: 2px dotted #ccc;
    border-radius: 5px;
    display: inline-block;
}

.dropzone-active {
    border: 2px dotted var(--primary);
}

.dropzone-placeholder {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 75px;
    line-height: 75px;
    width: 100%;
}

.careem-primary {
    color: var(--primary);
}